<template>
	<div 
		class="pik-widget pretest w-full h-full text-sm leading-4"
	>
		<div v-if="fancyBoxImg" class="fancy-box fixed inset-0">
			<div 
				class="absolute w-full h-full bg-black opacity-30"
				@click="fancyBoxImg = null"
			></div>
			<div 
				class="absolute w-full h-full p-x2 pointer-events-none"
			>
				<img 
					class="m-auto max-w-full max-h-full object-contain"
					:src="fancyBoxImg" 
				/>
			</div>
			<button 
				class="absolute top-0 right-0 m-x1 px-4 py-2 rounded-2xl bg-black text-white text-xs border-white border-1 border-solid"
				@click="fancyBoxImg = null"
			>minimize image</button>
		</div>
		<template v-if="isLoading">
			<div class="w-full min-height h-full flex flex-row justify-center items-center">
				<LoadingSpinner
				></LoadingSpinner>
			</div>
		</template>
		<template v-else>
			<div class="w-full h-full flex flex-col justify-center items-center">
				<div 
					v-if="isMissingJobnum"
					class="w-full text-center p-x2 font-medium text-20px bg-black text-white"
				>
					<p>Missing Jobnumber</p>
				</div>
				<div 
					v-else-if="isMissingToken"
					class="w-full text-center p-x2 font-medium text-20px bg-black text-white"
				>
					<p>Please <a 
						class="underline" 
						:href="loginUrl"
						style="text-underline-offset: 4px;"
					>login</a> to complete pretest for credit.</p>
				</div>
				<div 
					v-else-if="isError"
					class="w-full text-center p-x2 font-medium text-20px bg-black text-white"
				>
					<p v-html="errorMessage"></p>
				</div>
				<div 
					v-else
					ref="pretestEl"
					class="w-full h-full flex flex-col gap-x1"
				>
					<section class="relative flex-1 overflow-auto">
            <div 
              v-if="isEmailSubmit"
              class="w-full h-full flex justify-center items-center text-center"
              v-html="emailSubmitMessage"
            >
            </div>
						<div 
              v-else
              class="absolute inset-0"
            >
								<div class="relative w-full h-full overflow-y-scroll">
									<div class="w-full h-full flex flex-col gap-x2">
										<div class="flex flex-col gap-x1">
											<p class="font-medium" v-html="qtext"></p>
											<p 
												v-if="subquestion" 
												class="font-medium leading-tight"
												v-html="subquestionText"
											></p>
										</div>
										<div class="relative flex-1">
											<form 
												id="pretestForm"
												@submit.prevent="handleSubmit"
											>
												<div class="flex flex-col gap-x1.5">
													<div
														v-for="(a, a_ndx) in answers"
														:key="a_ndx"
														class="flex flex-row gap-x1 justify-start items-start"
													>
														<template v-if="selectedChoice">
															<input
																:id="a.ansid"
																class="hidden"
																:name="a.ansid"
																type="radio"
																:value="a.ansid"
																v-model="selectedChoice"
															/>
														</template>
														<template v-else>
															<input
																:id="a.ansid"
																class="hidden"
																:name="a.ansid"
																type="radio"
																:value="a.ansid"
																v-model="selectedChoice"
																required
															/>
														</template>
														<label 
															class="w-16px min-w-16px max-w-16px h-16px min-h-16px max-h-16px bg-gray-003 rounded-full p-3px cursor-pointer"
															:for="a.ansid"
														>
															<div
																v-if="a.ansid === selectedChoice"
																class="w-full h-full bg-orange rounded-full"
																:class="{
																	'text-orange': a.ansid === selectedChoice 
																}"
															></div>
														</label>
														<label
															class="leading-4 cursor-pointer"
															:class="{
																'text-orange': a.ansid === selectedChoice 
															}"
															:for="a.ansid"
															v-html="a.atext"
														></label>
													</div>
												</div>
											</form>
										</div>
									</div>
							</div>
						</div>
					</section>
					<footer class="w-full flex flex-col gap-x0.5 laptop:gap-x1">
						<div class="progress flex flex-row items-center gap-x2">
							<div class="h-1 flex-1 bg-gray-002 rounded-default overflow-hidden">
								<div 
									class="h-full bg-orange"
									:style="{
										width: percentageWidth
									}"
								></div>
							</div>
							<div class="text-orange text-medium">
								<span>{{ qrank }}</span> / <span>{{ count }}</span>
							</div>
						</div>
            <template v-if="isEmailSubmit">
              <button 
                class="w-full mt-x1 p-x1 laptop:p-x1.5 border-1 border-solid border-black rounded-default bg-black text-white text-sm"
                @click="handleContinue"
              >Continue with Pretest</button>
							<button 
								class="text-gray-006"
								@click="handleSkipAll"
							><small>Skip Pretest and Continue to Activity</small></button>
            </template>
            <template v-else>
              <template v-if="selectedChoice">
                <template v-if="showConfidence">
                  <small class="text-orange text-center">Please submit your confidence below</small>
                  <button 
                    class="w-full p-x1 laptop:p-x1.5 border-1 border-solid border-black rounded-default bg-black text-white text-sm"
                    @click="confidenceLevel = 5"
                    type="submit"
                    form="pretestForm"
                  >Very Confident</button>
                  <button 
                    class="w-full p-x1 laptop:p-x1.5 border-1 border-solid border-black rounded-default bg-black text-white text-sm"
                    @click="confidenceLevel = 3"
                    type="submit"
                    form="pretestForm"
                  >Somewhat Confident</button>
                  <button 
                    class="w-full p-x1 laptop:p-x1.5 border-1 border-solid border-black rounded-default bg-black text-white text-sm"
                    @click="confidenceLevel = 1"
                    type="submit"
                    form="pretestForm"
                  >Not Confident At All</button>
                </template>
                <template
                  v-else
                >
                  <button 
                    class="w-full p-x1 laptop:p-x1.5 border-1 border-solid border-black rounded-default bg-black text-white text-sm"
                    @click="confidenceLevel = -1"
                    type="submit"
                    form="pretestForm"
                  >Submit</button>
                </template>
              </template>
              <template v-else>
                <button 
                  class="text-gray-006"
                  @click="handleSkip"
                ><small>Skip this question</small></button>
              </template>
            </template>
					</footer>
				</div>
			</div>
		</template>
	</div>
</template>

<script setup>
import { defineEmits, defineProps, ref, onMounted, computed } from 'vue'
import { LoadingSpinner } from '@/components/'

const emit = defineEmits()

// Props
const props = defineProps({
	jobnum: {
		type: String,
		default: null
	},
	token: {
		type: String,
		default: null
	},
	loginRedirectUrl: {
		type: String,
		default: 'https://suiteweb.atpointofcare.com/#user/login'
	},
	source: {
		type: String,
		default: 'suite-web'
	},
	isMulti: {
		type: Boolean,
		default: false
	}
})

// DOM
const pretestEl = ref(null)
const fancyBoxEls = ref([])

// Variables
const pretestStatus = ref(null)
const pretestData = ref(null)
const isLoading = ref(true)
const isError = ref(false)
const errorMessage = ref(null)
const selectedChoice = ref(null)
const confidenceLevel = ref(null)
const fancyBoxImg = ref(null)
const isEmailSubmit = ref(false)
const emailSubmitMessage = ref('<p>Thank you for sharing your valuable insights.<br>Your answer has been recorded.</p>')

// Computed
const loginUrl = computed(() => {
	const base = props.loginRedirectUrl
	const params = `redirect=true&redirect_url=${ encodeURIComponent(window.location) }`
	if (base.includes('#')) {
		const split = base.split('#')
		return `${ split[0] }?${ params }#${ split[1] }`
	}
	else {
		return `${ base }?${ params }`
	}
})

const isMissingToken = computed(() => {
  return !props.token || (props.token && (props.token === 'undefined' || props.token === 'null'))
})

const isMissingJobnum = computed(() => {
  return !props.jobnum
})

const nextQid = computed(() => {
	return pretestStatus.value?.next_qid
})

const nextSubQid = computed(() => {
	return pretestStatus.value?.next_subqid
})

const questions = computed(() => {
	return pretestData.value 
		?.questions?.map(function (question) {
			if (question.type == 40) {
				return question;
			} else if (question.type == 20) {
				return question.subquestions.map(function (subquestion) {
					return {
						qid: question.qid,
						qrank: "".concat(+question.qrank + +subquestion.srank - 1),
						qtext: question.qtext,
						answers: question.answers,
						subquestion: subquestion
					};
				});
			}
		})?.flat()
})

const currQuestion = computed(() => {
	if (!nextQid.value) 
	{
		return questions.value?.[0]
	} 
	else if (nextQid.value && nextSubQid.value == 0 || !nextSubQid.value) 
	{
		return questions.value
			.find(function (question) {
				return question.qid == nextQid.value;
			})
	} 
	else if (nextQid.value && nextSubQid.value != 0 || nextSubQid.value) 
	{
		return questions.value
			.find(function (question) {
				return question.qid == nextQid.value && question.subquestion.subqid == nextSubQid.value;
			})
	}
	else return null	
})

const qtext = computed(() => {
  return currQuestion.value?.qtext
})

const subquestion = computed(() => {
  return currQuestion.value?.subquestion
})

const subquestionText = computed(() => {
  return currQuestion.value?.subquestion?.stext
})

const answers = computed(() => {
 return  currQuestion.value?.answers
})

const qrank = computed(() => {
 return  isEmailSubmit.value
  ? currQuestion.value?.qrank - 1
  : currQuestion.value?.qrank
})

const currQuestionIndex = computed(() => {
	if (!nextQid.value) 
	{
		return 0
	} 
	else if (nextQid.value && nextSubQid.value == 0 || !nextSubQid.value) 
	{
		return questions.value
			.findIndex(function (question) {
				return question.qid == nextQid.value;
			})
	} 
	else if (nextQid.value && nextSubQid.value != 0 || nextSubQid.value) 
	{
		return questions.value
			.findIndex(function (question) {
				return question.qid == nextQid.value && question.subquestion.subqid == nextSubQid.value;
			})
	}
	else return null	
})

const isIdkSelected = computed(() => {
	return !!selectedChoice.value 
		&& currQuestion.value.answers
			.filter((ans) => ans.ansid === selectedChoice.value)[0]
			.atext
			.toLowerCase() === "i do not know"
})

const showConfidence = computed(() => {
	return qType.value == '40' && !isIdkSelected.value
})

const qType = computed(() => {
	return pretestData.value 
		.questions
		.find(function (question, q_ndx) {
			return nextQid.value
				? question.qid == nextQid.value
				: q_ndx === 1;
		})
		?.type
})

const payload = computed(() => {
	return {
		qid: currQuestion.value?.qid,
		subqid: currQuestion.value?.subquestion?.subqid || '',
		ansid: selectedChoice.value,
		confidence: confidenceLevel.value,
		source: getUrlParam('presrc') || props.source
	}
})

const statusEndpoint = computed(() => {
	return props.isMulti === 'true' || props.isMulti === 't'
		? process.env.VUE_APP_PRETEST_COMBINED_API_URL
		: process.env.VUE_APP_PRETEST_API_URL
})

const contentEndpoint = computed(() => {
	return props.isMulti === 'true' || props.isMulti === 't'
		? process.env.VUE_APP_TEST_COMBINED_API_URL
		: process.env.VUE_APP_TEST_API_URL
})

const qidParam = computed(() => {
 return getUrlParam('preqid') || null
})

const subQidParam = computed(() => {
 return getUrlParam('presubqid') || ''
})

const ansidParam = computed(() => {
 return getUrlParam('preansid') || null
})

const percentageWidth = computed(() => {
  return qrank.value && questions.value
    ? `${ (qrank.value / questions.value.length) * 100 }%`
    : '0%'
})

const count = computed(() => {
  return questions.value?.length
})

// Life Cycle Hooks
onMounted(async () => {
  window.pretestSkipAll = handleSkipAll
  if (isMissingJobnum.value || isMissingToken.value) {
    isLoading.value = false
    return false
  }
  else {
    if (qidParam.value && ansidParam.value) {
      const paramPayload = {
        qid: qidParam.value,
        subqid: subQidParam.value || '',
        ansid: ansidParam.value,
        confidence: null,
        source: getUrlParam('presrc') || props.source
      }
      await submitPretest(paramPayload)
      isEmailSubmit.value = true
      emit('submit-pretest', {
        jobnum: props.jobnum,
        token: props.token,
        payload: paramPayload,
      })
      clearUrlParams(['preqid', 'presubqid', 'preansid', 'presrc'])
    }
    else {
      await checkProgress()
    }
    
    await getPretestData()
    initFancyBox()
    isLoading.value = false
  }
})

// Functions
const initFancyBox = () => {
	setTimeout(() => {
		const fancyBoxes = pretestEl.value?.querySelectorAll('.fancybox')
		
		fancyBoxes
			?.forEach((fancyBox) => {
				const img = fancyBox.querySelector('img')
				img.addEventListener('click', (event) => {
					event.preventDefault()
				})

				fancyBoxEls.value.push(fancyBox)
				fancyBox.addEventListener('click', (event) => {
					event.preventDefault()
					showFancyBox(fancyBox)
				})
			})
	}, 500)
}

const showFancyBox = (el) => {
	const href = el.getAttribute('href')

	fancyBoxImg.value = href
}

const handleSkip = async () => {
	const eventPayload = Object.assign({ 
		...payload.value 
	}, {
		ansid: '-1'
	})
	isLoading.value = true
	await submitPretest(eventPayload)
	emit('skip-pretest', {
		jobnum: props.jobnum,
		token: props.token,
		payload: eventPayload,
	})
	selectedChoice.value = null
  isLoading.value = false
}

const handleSkipAll = async () => {
	isLoading.value = true
  const payloads = []
  for (let i = currQuestionIndex.value; i < questions.value.length; i++) {
    const q = questions.value[i]
    const payload = {
      qid: q?.qid,
      subqid: q?.subquestion?.subqid || '',
      ansid: -1,
      confidence: null,
      source: getUrlParam('presrc') || props.source
    }

    try {
      await submitPretest(payload)
      payloads.push(payload)
    }
    catch (err) {
      isError.value = true
      errorMessage.value = err?.message || err
      isLoading.value = false
      return
    }
  }

  emit('skipped-all', {
    jobnum: props.jobnum,
    token: props.token,
    payload: payloads,
  })

  emit('is-completed', {
    status: 'complete',
    jobnum: props.jobnum,
    token: props.token,
  })

  isLoading.value = false
}

const handleSubmit = async () => {
	const eventPayload = { 
		...payload.value 
	}
	isLoading.value = true
	await submitPretest(eventPayload)
	emit('submit-pretest', {
		jobnum: props.jobnum,
		token: props.token,
		payload: eventPayload,
	})
	selectedChoice.value = null
	confidenceLevel.value = null
  isLoading.value = false
}

const handleContinue = () => {
  isEmailSubmit.value = false
}

const submitPretest = async (payload) => {
  try { 
    const url = `${ statusEndpoint.value }/${ props.jobnum }?token=${ props.token }`
    const body = JSON
      .stringify(Object.assign(payload, {
        utm_campaign: getUrlParam('utm_campaign') || ''
      }))

    const req = await fetch(url, {
      method: 'POST',
      body
    })
    const res = await req.json()

    if (res.status.includes('Error')) throw (res.status)
    else if (res.status == 'complete') {
      emit('is-completed', {
        status: 'complete',
        jobnum: props.jobnum,
        token: props.token,
      })
      throw ('Pretest is complete.')
    }

    pretestStatus.value = res
  }
  catch (err) {
		isError.value = true
		errorMessage.value = err?.message || err
		isLoading.value = false
  }
}

const getPretestData = async () => {
  try { 
    const url = `${ contentEndpoint.value }/${ props.jobnum }?token=${ props.token }`
    
    const req = await fetch(url)
    const res = await req.json()
    
    if (res.error) throw (res.error)

    pretestData.value = res?.pretest
  }
  catch (err) {
		isError.value = true
		errorMessage.value = err?.message || err
		isLoading.value = false
  }
}

const checkProgress = async () => {
  try {
    const url = `${ statusEndpoint.value }/${ props.jobnum }?token=${ props.token }`
    
    const req = await fetch(url)
    const res = await req.json()

    if (res.status.includes('Error')) throw (res.status)
    else if (res.status == 'complete') {
      emit('is-completed', {
        status: 'complete',
        jobnum: props.jobnum,
        token: props.token,
      })
      throw ('Pretest is complete.')
    }
    
    pretestStatus.value = res
  }
  catch (err) {
		isError.value = true
		errorMessage.value = err?.message || err
		isLoading.value = false
  }
}

const getUrlParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}

function clearUrlParams(params) {
  const url = new URL(window.location.href);
  params.forEach(param => url.searchParams.delete(param));
  const newUrl = url.toString();
  window.history.replaceState({}, document.title, newUrl);
}
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>

<style scoped>
.fancy-box { z-index: 9999999999; }
</style>