<template>
	<div 
		class="pik-widget breakingmed-feed border-1 border-black bg-white"
		:class="[
			{
				'border-black': mode === 'dark',
				'border-white': mode === 'light',
			}
		]"
	>
		<header 
			class="header flex flex-row justify-center items-center p-default h-60px"
			:class="{
				'bg-black': mode === 'dark',
				'bg-white': mode === 'light',
			}"
		>
			<a
				href="https://breakingmed.atpointofcare.com/"
				target="_blank"
			>
				<img class="h-40px" :src="logoSrc" />
			</a>
		</header>
		<section class="content px-5 py-3">
			<div class="text-red border-b-1 border-red">
				<h3 class="font-bold mb-3 text-16px">LATEST ARTICLES</h3>
			</div>
			<div>
				<div
					v-if="isLoading"
					class="px-4 py-20"
				>
					<LoadingSpinner 
						color="red"
						size="100"
						thickness="12"
					/>
				</div>
				<div v-else>
					<template v-if="isError">
						<div class="min-h-100px flex flex-row items-center py-3"><p class="font-medium">An error has occurred. Please try again later.</p></div>
					</template>
					<template v-else-if="!isError && data.length === 0">
						<div class="min-h-100px flex flex-row items-center py-3"><p class="font-medium">No articles found.</p></div>
					</template>
					<template v-else>
						<a
							v-for="(item, ndx) in data"
							:key="ndx"
							:href="item.href"
							:target="target"
							class="hover:text-red"
						>
							<div
								class="flex flex-col gap-2 py-3 font-medium"
								:class="[
									{
										'border-b-1 border-gray-005': (ndx + 1) < data.length
									}
								]"
							>
								<p class="text-14px" v-html="item.title"></p>	
								<!-- <p class="text-10px text-gray-006">{{ item.released_str }}</p> -->
							</div>
						</a>
					</template>
				</div>
			</div>
		</section>
	</div>
</template>

<script setup>
import { ref, onMounted, defineProps } from 'vue'
import store from '@/store'

import { LoadingSpinner } from '@/components/'

const props = defineProps({
	endpoint: {
		type: String,
		default: null
	},
	logo: {
		type: Object,
		default: null
	},
	mode: {
		type: String,
		default: 'dark'
	},
	target: {
		type: String,
		default: '_blank'
	},
	taNum: {
		type: String,
		default: null
	},
	userToken: {
		type: String,
		default: null
	},
	max: {
		type: String,
		default: null
	},
	sortBy: {
		type: String,
		default: 'latest'
	}
})

const isLoading = ref(true)
const isError = ref(false)
const defaults = store.getters?.breakingMedFeed
const logoSrc = props?.logo || defaults?.logos[props.mode]
const data = ref(null)

onMounted(async () => {
	await getData()
	isLoading.value = false
})

const getData = async () => {
	try {
		const req = await fetch(`${ props?.endpoint || defaults?.endpoint }?ta_num=${ props?.taNum || '' }`)
		const res = await req.json()
		const output = sortData(
			Object
				.keys(res)
				.map((key) => {
					return {
						...res[key],
						released_str: timeDifference(res[key].releasedate),
						href: `https://breakingmed.atpointofcare.com/activities/${ 
							res[key].articleid 
						}/?referrer=${ 
							encodeURIComponent(window.location.href)
						}`
					}
				})
		)

		data.value = output
	}
	catch (err) {
		isError.value = true
	}
}

const sortData = (input) => {
	if (props?.sortBy === 'alpha') {
		return input.sort((a, b) => {
			if (a.title < b.title) {
				return -1;
			}
			if (a.title > b.title) {
				return 1;
			}
			return 0;
		})
	}
	else if (props?.sortBy === 'reverse alpha') {
		return input.sort((a, b) => {
			if (a.title > b.title) {
				return -1;
			}
			if (a.title < b.title) {
				return 1;
			}
			return 0;
		})
	}
	else if (props?.sortBy === 'oldest') {
		return input.sort((a, b) => new Date(a.releasedate) - new Date(b.releasedate))
	}
	else {
		return input.sort((a, b) => new Date(b.releasedate) - new Date(a.releasedate))
	}
}

const timeDifference = (timestamp) => {
	var end = new Date();
	var start = new Date(timestamp);
	var difference = end.getTime() - start.getTime();

	return difference > 86400000 ? Math.trunc(difference/1000/60/60/24) + ' d' 
		: difference > 3600000 ? Math.trunc(difference/1000/60/60) + ' hr' 
		: difference > 60000 ? Math.trunc(difference/1000/60/60) + ' min' 
		: Math.trunc(difference/1000/60) + ' sec';
}
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>